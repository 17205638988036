/* General button styles */
.button_base {
  color: white; /* White */
  border: none;
  border-radius: 5px; /* 5px */
  padding: 10px 20px; /* 10px 20px */
  font-size: 15px; /* 15px */
  font-weight: bold; /* bold */
  font-family: Arial, sans-serif; /* Arial, sans-serif */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
}

.button_base:focus {
  outline: 2px solid transparent;
  outline-offset: 2px; /* Fixed to 2px */
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1); /* Default box-shadow */
  color: white; /* White */
}

.button_icon {
  display: flex;
  align-items: center;
  padding: 0; /* Remove padding inherited from .button_base */
  color: black; /* Black */
  background: none;
  margin-left: 5px;
  margin-bottom: 10px;
  width: 60px; /* Prevent full-width issue */
  transition: color 0.3s, background 0.3s, outline 0.3s, box-shadow 0.3s, transform 0.2s; /* Added transitions */
  outline: 2px solid transparent; /* Set initial outline to transparent to avoid flash */
}

.button_icon:hover {
  color: black; /* Color change on hover */
  background-color: #f5f5f5; /* Light grey background on hover */
  outline: 2px solid #f5f5f5; /* Outline color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.button_icon:focus {
  color: black; /* Color change on focus */
  outline: 2px solid #b0b0b0; /* Grey color on focus */
  background-color: #f5f5f5; /* Light grey background on focus */
}

/* Red button styles */
.button_red {
  background-color: #e53e3e; /* #e53e3e */
}

.button_red:hover {
  background-color: #c53030; /* #c53030 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_red:active {
  outline-color: #e53e3e; /* #e53e3e */
  box-shadow: 0 0 0 2px rgba(229, 62, 62, 0.4);
}

/* Blue button styles */
.button_blue {
  background-color: #485fc7; /* #485fc7 */
}

.button_blue:hover {
  background-color: #3d4db3; /* #3d4db3 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_blue:active {
  outline-color: #485fc7; /* #485fc7 */
  box-shadow: 0 0 0 2px rgba(72, 95, 199, 0.4);
}

.icon_spacing {
  margin-right: 5px;
}

/* Teal button styles */
.button_teal {
  background-color: #319795; /* #319795 */
  transition: background-color 0.3s, box-shadow 0.2s;
}

.button_teal:hover {
  background-color: #2c7a7b; /* #2c7a7b */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_teal:active {
  outline-color: #319795; /* #319795 */
  box-shadow: 0 0 0 2px rgba(49, 151, 149, 0.4);
}

/* Grey button styles */
.button_grey {
  background-color: #4a5568; /* #4A5568 */
  transition: background-color 0.3s, box-shadow 0.2s;
}

.button_grey:hover {
  background-color: #cccccc; /* #cccccc */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_grey:active {
  outline-color: #4a5568; /* #4A5568 */
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
}
