body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.flex-wrap {
  flex-wrap: wrap;
}

.max-width-sm {
  max-width: 400px;
}

.max-width-md {
  max-width: 600px;
}

.max-width-lg {
  max-width: 900px;
}

.button-width {
  width: 200px;
}

.button-width-sm {
  width: 100px;
}

.center {
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.table.is-borderless td, .table.is-borderless th {
 border: 0;
}
.bottom-border {
  border-bottom: 1px #A9A9A9 solid;
}
tr.space>td {
  padding-top: 1em;
  padding-bottom: 1em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}
